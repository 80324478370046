import React, { useState, useEffect } from "react";
import { IconButton } from "@material-tailwind/react";

export default function StickyNavbar() {
  const [scrolling, setScrolling] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const handleNavLinkClick = () => {
    setOpenNav(false); // Cerrar el menú de navegación al hacer clic en un enlace
  };

  const textColor = scrolling ? "text-[#09588d]" : "text-white";
  const iconColor = scrolling ? "text-[#09588d]" : "text-white";
  const logoSrc = scrolling ? "https://i.ibb.co/d6GLcWh/logoo.png" : "https://i.ibb.co/6gHQQbD/logoblanco.png";
  const bgColor = openNav && !scrolling ? "bg-[#09588d]" : "bg-transparent";
  const responsiveBgColor = openNav && !scrolling ? "bg-[#09588d] w-auto" : scrolling ? "bg-white" : "bg-transparent";

  return (
    <nav className={`fixed z-10 top-0 w-full rounded-none border-none ${scrolling ? "bg-white shadow-xl" : "bg-transparent"}`}>
      <div className={`flex items-center justify-between w-full ${responsiveBgColor}`}>
        <div className="flex items-center p-2">
          <img src={logoSrc} alt="Logo" width={150} />
        </div>
        <div className={`hidden lg:flex items-center gap-4 flex-grow justify-end p-3 ${textColor}`}>
          <a className="font-micrograma" href="#quienes-somos" onClick={handleNavLinkClick}>¿Quiénes Somos?</a>
          <a className="font-micrograma" href="#nuestra-experiencia" onClick={handleNavLinkClick}>Experiencia</a>
          <a className="font-micrograma" href="#nuestro-compromiso" onClick={handleNavLinkClick}>Compromiso</a>
          <a className="font-micrograma" href="#nuestros-servicios" onClick={handleNavLinkClick}>Servicios</a>
          <a className="font-micrograma" href="#beneficios" onClick={handleNavLinkClick}>Trabajar con nosotros</a>
          <a className="font-micrograma" href="#contactenos" onClick={handleNavLinkClick}>Contáctenos</a>
        </div>
        <IconButton
          placeholder={""}
          variant="text"
          className={`lg:hidden mr-4 h-6 w-6 ${iconColor} ${bgColor} hover:bg-transparent focus:bg-transparent active:bg-transparent`}
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </IconButton>
      </div>
      {openNav && (
        <div className={`lg:hidden flex flex-col items-center p-4 space-y-4 ${responsiveBgColor} w-full`}>
          <a className={`font-micrograma ${scrolling ? "text-[#09588d]" : "text-white"}`} href="#quienessomos" onClick={handleNavLinkClick}>¿Quiénes Somos?</a>
          <a className={`font-micrograma ${scrolling ? "text-[#09588d]" : "text-white"}`} href="#nuestraexperiencia" onClick={handleNavLinkClick}>Experiencia</a>
          <a className={`font-micrograma ${scrolling ? "text-[#09588d]" : "text-white"}`} href="#nuestrocompromiso" onClick={handleNavLinkClick}>Compromiso</a>
          <a className={`font-micrograma ${scrolling ? "text-[#09588d]" : "text-white"}`} href="#nuestrosservicios" onClick={handleNavLinkClick}>Servicios</a>
          <a className={`font-micrograma ${scrolling ? "text-[#09588d]" : "text-white"}`} href="#beneficios2" onClick={handleNavLinkClick}>Trabajar con nosotros</a>
          <a className={`font-micrograma ${scrolling ? "text-[#09588d]" : "text-white"}`} href="#contactenos2" onClick={handleNavLinkClick}>Contáctenos</a>
        </div>
      )}
    </nav>
  );
}
