import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";

interface CardCProps {
  title: string;
  description: string;
  foot?: string;
  img?: string;
  width?: number;
  height?: number;
}

const CardH: React.FC<CardCProps> = ({
  title,
  description,
  foot,
  img,
  width,
  height,
}) => {
  return (
    <Card
      placeholder={""}
      className="w-full md:w-[300px] lg:w-[600px] xl:w-[1000px] shadow-2xl scale-90 hover:scale-100 ease-in duration-100 font-micrograma grid grid-cols-1 md:grid-cols-2"
    >
      <div className="flex justify-center items-center p-4">
        <img
          src={img}
          alt="state"
          className="max-w-full max-h-full"
          width={width}
          height={height}
        />
      </div>
      <div className="flex flex-col p-4">
        <CardBody placeholder={""} className="text-justify text-black">
          <Typography placeholder={""} variant="h5" className="mb-2">
            {title}
          </Typography>
          <Typography placeholder={""}>{description}</Typography>
        </CardBody>
        <CardFooter
          placeholder={""}
          divider
          className="flex items-center justify-between py-3 text-black"
        >
          <Typography placeholder={""} variant="small">
            {foot}
          </Typography>
          <Typography
            placeholder={""}
            variant="small"
            color="gray"
            className="flex gap-1 text-black"
          >
            <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
            CS2
          </Typography>
        </CardFooter>
      </div>
    </Card>
  );
};

export default CardH;
