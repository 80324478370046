import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";

interface CardCProps {
  title: string;
  description: string;
  foot?: string;
  img?: string;
  width?: number;
  height?: number;
}

const CardC: React.FC<CardCProps> = ({
  title,
  description,
  foot,
  img ,
  width,
  height,
}) => {
  return (
    <Card placeholder={""} className="w-auto md:w-96 shadow-2xl scale-90 hover:scale-100 ease-in duration-100 font-micrograma">
      <img src={img} alt="state" className="m-auto mt-10" width={width} height={height} />
      <CardBody placeholder={""} className="text-justify text-black">
        <Typography placeholder={""} variant="h5" className="mb-2">
          {title}
        </Typography>
        <Typography placeholder={""}>{description}</Typography>
      </CardBody>
      <CardFooter
        placeholder={""}
        divider
        className="flex items-center justify-between py-3 text-black"
      >
        <Typography placeholder={""} variant="small">{title}</Typography>
        <Typography
          placeholder={""}
          variant="small"
          color="gray"
          className="flex gap-1 text-black"
        >
          <i className="fas fa-map-marker-alt fa-sm mt-[3px]" />
          CS2
        </Typography>
      </CardFooter>
    </Card>
  );
};

export default CardC;
