import React from 'react'
import CardC from '../../../../components/card'
import CardH from '../../../../components/card-horizontal'
import StickyNavbar from '../../../../components/nav-bar'
import WavesDivider from '../../../../components/WavesDivider'
import { Carousel } from '@material-tailwind/react'

export default function PaginaPrincipal(){
  return (
    <>
        <div className='overflow-auto'>
            <div className='hidden xl:block'>
                <div className=' h-[702px]' style={{ backgroundImage: "url('https://i.ibb.co/Wk8VMWD/img-principal.jpg')", backgroundSize: 'cover', backgroundPositionY: -100 }}/>
                <StickyNavbar></StickyNavbar>
                <div className='relative rotate-180 -mt-32 bg-inherit' >
                    <WavesDivider className='rotate-180 '></WavesDivider>
                    <g id="Capa_2" data-name="Capa 2">
                        <g id="Capa_1-2" data-name="Capa 1">
                            <path
                                class="cls-1"
                                d="M21.83,16.16a1.5,1.5,0,0,1-1.06,2.37l-9.56,1-9.55,1a1.5,1.5,0,0,1-1.53-2.1L4,9.67,7.94.89A1.5,1.5,0,0,1,10.53.62l5.65,7.77Z"
                            />
                        </g>
                    </g>
                </div>
                <div className='flex grid grid-cols-2 0' id="quienes-somos"> 
                    <div className='mt-20'>
                        <img src='https://i.ibb.co/hZHvjc2/imgmap.png' alt="" width={"90%"}/>
                    
                    </div>
                    <QuienesSomos/>
                </div>
                <div className=' bg-[#09588d] mt-5' id="nuestra-experiencia">
                    <div className='flex grid grid-cols-2 p-32'>

                        <NuestraExperiencia/>
                        <img src='https://i.ibb.co/C9cH05h/team.png' alt="" width={"90%"} className='ml-16 mt-24'/>
                        
                    </div>
                </div>   
                <div className='mt-12' id="nuestro-compromiso">
                    <div className='flex grid grid-cols-2 0'>
                        <img src='https://i.ibb.co/VHbrbbM/help.png' alt="" width={"90%"} className=' mt-16'/>
                        <div className='ml-24'>
                            <NuestroCompromiso/>
                        </div>
                    </div>

                </div>
                <div className='bg-[#09588d] mt-12' id="nuestros-servicios">
                    <div className='0'>
                        <NuestrosServicios/>
                    </div>
                </div>
                <div className='bg-[#F8F8F8]' id="otros-servicios">
                    <OtrosServicios/>
                </div>
                <div className='bg-[#09588d] p-32' id="ciberseguridad">  
                    <Ciberseguridad/>
                </div>
                <div className='0' id="beneficios">
                    <Beneficios/>
                </div>
                <div className='relative' id="text">
                    <div style={{ backgroundImage: "url('https://i.ibb.co/gFD9nyJ/mapa.jpg')"}}>
                        <Text className="absolute inset-0 flex items-center justify-center"/>
                    </div>
                    
                </div>
                <div className='' >
                    <img src='https://i.ibb.co/5Ws7d87/contact.jpg' alt='' />
                </div>
                <div className='bg-[#112640] 0' id="contactenos">
                    <Contactenos/>
                </div>
                
            </div>
            <div className='block block xl:hidden'>
                <div className=' h-[500px] w-full  bg-cover bg-center' style={{ backgroundImage: "url('https://i.ibb.co/Wk8VMWD/img-principal.jpg')"}}/>
                    <StickyNavbar></StickyNavbar>
                    <div className='relative rotate-180 -mt-9 bg-inherit' >
                        <WavesDivider className='rotate-180 '></WavesDivider>
                        <g id="Capa_2" data-name="Capa 2">
                            <g id="Capa_1-2" data-name="Capa 1">
                                <path
                                    class="cls-1"
                                    d="M21.83,16.16a1.5,1.5,0,0,1-1.06,2.37l-9.56,1-9.55,1a1.5,1.5,0,0,1-1.53-2.1L4,9.67,7.94.89A1.5,1.5,0,0,1,10.53.62l5.65,7.77Z"
                                />
                            </g>
                        </g>
                    </div>
                    <div className='mt-20' id="quienessomos"> 
                        <QuienesSomos/>
                        <div className='mt-20'>
                            <img src='https://i.ibb.co/hZHvjc2/imgmap.png' alt="" width={"90%"}/>
                        
                        </div>
                    </div>
                    <div className='p-2 bg-[#09588d] mt-5' id="nuestraexperiencia">
                        <div className=' '>

                            <NuestraExperiencia/>
                            <img src='https://i.ibb.co/C9cH05h/team.png' alt="" width={"90%"} className='mt-6 p-2 ml-4 mr-4 mb-3'/>
                            
                        </div>
                    </div>   
                    <div className='mt-12' id="nuestrocompromiso">
                        <div className=''>
                            <div className=''>
                                <NuestroCompromiso/>
                            </div>
                            <img src='https://i.ibb.co/VHbrbbM/help.png' alt="" width={"90%"} className=' mt-16'/>
                        </div>

                    </div>
                    <div className='bg-[#09588d] mt-12' id="nuestrosservicios">
                        <div className=''>
                            <NuestrosServicios/>
                        </div>
                    </div>
                    <div className='bg-[#F8F8F8]' id="otrosservicios">
                        <OtrosServicios/>
                    </div>
                    <div className='bg-[#09588d] ' id="ciberseguridad2">  
                        <Ciberseguridad/>
                    </div>
                    <div className='' id="beneficios2">
                        <Beneficios/>
                    </div>
                    <div className='relative' id="text">
                        <div style={{ backgroundImage: "url('https://i.ibb.co/gFD9nyJ/mapa.jpg')"}}>
                            <Text className="absolute inset-0 flex items-center justify-center"/>
                        </div>
                        
                    </div>
                    <div className='' >
                        <img src='https://i.ibb.co/5Ws7d87/contact.jpg' alt='' />
                    </div>
                    <div className='bg-[#112640] ' id="contactenos2">
                        <Contactenos/>
                    </div>
                </div>
        </div>

    </>
  )
}

export function QuienesSomos() {
  return (   
      <>  
        <div>
            <div className='hidden xl:block'>
                <div className=' w-[520px] ml-24'>
                    <p className='text-start font-normal text-6xl text-[#09588d]'>¿Quiénes <br></br> somos?</p>
                    <p className='text-justify font-light mt-10 text-2xl text-[#09588d] '>
                        Somos una empresa de consultoría 
                        en la seguridad de la cadena logística. Ayudamos a
                        las empresas a identificar, evaluar y mitigar los
                        riesgos de seguridad en toda su línea de suministro,
                        desde los proveedores hasta los clientes, con el
                        respaldo y la implementación de sistemas
                        electrónicos de seguridad basados en información
                        geográfica y garantizando la ciberseguridad.
                    </p>
                </div>
            </div>
            <div className='block xl:hidden p-3'>
                <div className=' w-auto ml-4 m-4'>
                    <p className='font-normal text-4xl text-[#09588d]'>¿Quiénes somos?</p>
                    <p className='text-justify font-light mt-6 text-xl text-[#09588d] '>
                        Somos una empresa de consultoría 
                        en la seguridad de la cadena logística. Ayudamos a
                        las empresas a identificar, evaluar y mitigar los
                        riesgos de seguridad en toda su línea de suministro,
                        desde los proveedores hasta los clientes, con el
                        respaldo y la implementación de sistemas
                        electrónicos de seguridad basados en información
                        geográfica y garantizando la ciberseguridad.
                    </p>
                </div>
            </div>
        </div>
      </>
  )
}

export function NuestraExperiencia() {
  return (
      <>
            <div>
                <div className='hidden xl:block'>
                    <div className=' mr-44 w-[520px]'>
                        <p className='text-start font-normal text-6xl text-white'>Nuestra <br></br> experiencia</p>
                        <p className='text-justify font-light mt-10 text-2xl text-white'>Contamos con un equipo de expertos profesionales
                            altamente capacitados e idóneos y con amplia
                            experiencia en seguridad de la cadena de suministro
                            con una reconocida trayectoria laboral en diferentes
                            áreas del saber y en procesos como los
                            procedimientos de antinarcóticos, inteligencia e
                            investigación criminal, lo que nos permite
                            comprender e identificar las brechas y así
                            potencializar las capacidades de su negocio.
                        </p>
                    </div>

                </div>
                <div className='block xl:hidden p-3'>
                    <div className=' mr-4 ml-4 w-auto'>
                        <p className='text-start font-normal text-4xl text-white'>Nuestra <br></br> experiencia</p>
                        <p className='text-justify font-light mt-6 text-xl text-white'>Contamos con un equipo de expertos profesionales
                            altamente capacitados e idóneos y con amplia
                            experiencia en seguridad de la cadena de suministro
                            con una reconocida trayectoria laboral en diferentes
                            áreas del saber y en procesos como los
                            procedimientos de antinarcóticos, inteligencia e
                            investigación criminal, lo que nos permite
                            comprender e identificar las brechas y así
                            potencializar las capacidades de su negocio.
                        </p>
                    </div>

                </div>
            </div>
      </>
  )
}

export function NuestroCompromiso() {
  return (
    <>
        <div>
            <div className='hidden xl:block'>
                <div className='w-[520px]'>
                    <p className='text-start font-normal text-6xl text-[#09588d]'>Nuestro <br></br>compromiso</p>
                    <p className='text-justify font-light mt-10 text-2xl text-[#09588d]'>
                        Estamos comprometidos a ayudar a nuestros
                        clientes a proteger sus cadenas de suministro de las
                        amenazas cada vez más sofisticadas.
                    </p>

                    <p className='text-justify font-light mt-10 text-2xl text-[#09588d]'>  
                        Brindamos servicios de alta calidad que se ajustan a
                        las necesidades específicas de cada cliente con el
                        máximo profesionalismo, compromiso y
                        confidencialidad.
                    </p>
                </div>
            </div>
            <div className='block xl:hidden p-3'>
                <div className='w-auto mr-4 ml-4'>
                    <p className='text-start font-normal text-4xl text-[#09588d]'>Nuestro <br></br>compromiso</p>
                    <p className='text-justify font-light mt-6 text-xl text-[#09588d]'>
                        Estamos comprometidos a ayudar a nuestros
                        clientes a proteger sus cadenas de suministro de las
                        amenazas cada vez más sofisticadas.
                    </p>

                    <p className='text-justify font-light mt-6 text-xl text-[#09588d]'>  
                        Brindamos servicios de alta calidad que se ajustan a
                        las necesidades específicas de cada cliente con el
                        máximo profesionalismo, compromiso y
                        confidencialidad.
                    </p>
                </div>
            </div>
        </div>
    </>
    
  )
}

export function NuestrosServicios() {
  return (
      <>
          
            <div className='p-6'>
                <div className='hidden xl:block'>
                    <p className='text-center font-normal text-6xl text-white'>Nuestros servicios</p>
                    <div className='flex justify-center items-center grid mt-12'>
                        <CardH
                            foot={""}
                            title={"Consultoria en seguridad de la cadena de suministro"}
                            img={"https://i.ibb.co/PtbP7n2/Warning.png"}
                            width={700}
                            description={
                                "Basados en las normas gubernamentales correspondientes y/o las de seguridad respectivas según su empresa. Realizamos el acompañamiento respectivo y permanente, desde el diagnostico hasta la aprobación de la entidad certificadora, mejorando la seguridad de la cadena de suministro internacional en cada uno de los actores que hacen parte de la misma."
                            }
                        ></CardH>

                        <CardH
                            foot={""}
                            title={"Auditoría de seguridad de la cadena de suministro"}
                            img={"https://i.ibb.co/hHG8kSb/develop.png"}
                            width={700}
                            description={
                                "Una auditoría de seguridad de la cadena de suministro internacional es un proceso independiente y sistemático para evaluar y analizar los riesgos de seguridad en una cadena de suministro. El objetivo principal es identificar, evaluar y mitigar los riesgos potenciales que podrían afectar la seguridad de los productos, la integridad de la cadena de suministro y la reputación de la organización."
                            }
                        ></CardH>

                        <CardH
                            foot={""}
                            title={"Pre - validaciones y mantenimiento de la certificacion o autorizacion oea"}
                            img={"https://i.ibb.co/fXBZKk5/controls.png"}
                            width={700}
                            description={
                                "Tanto la pre-validación como el mantenimiento de la certificación son procesos cruciales para las empresas que buscan fortalecer su posición en el comercio internacional. Si bien comparten el objetivo de demostrar el compromiso con la seguridad y la confiabilidad en la cadena de suministro, el objetivo es identificar posibles brechas en el cumplimiento de los criterios o requisitos mínimos requeridos de seguridad y brindar la asesoría para fortalecer las buenas prácticas de seguridad y gestión de riesgos de la empresa."
                            }
                        ></CardH>
                        <CardH
                            foot={""}
                            title={"Programas de capacitación en seguridad para la cadena de suministro"}
                            img={"https://i.ibb.co/h81ssG9/conference.png"}
                            width={800}
                            description={
                                "Nuestro programa de capacitación en seguridad de la cadena de suministro busca fortalecer las capacidades de una organización en la identificación, evaluación y mitigación de riesgos potenciales que puedan afectar la seguridad de la cadena de logística. De igual manera es importante concientizar a los empleados sobre la importancia de la seguridad en la cadena de suministro. Promover las buenas prácticas seguras a lo largo de toda la cadena de suministro y fomentando  la cultura de seguridad dentro de la organización."
                            }
                        ></CardH>
                    </div>
                </div>
                <div className="block xl:hidden">
                    <p className="text-center font-normal text-4xl text-white">
                        Nuestros servicios
                    </p>
                    <Carousel autoplay={true} loop={true} className="mt-16">
                        <div className="p-4">
                        <CardC
                            foot={""}
                            title={"Consultoria en seguridad de la cadena de suministro"}
                            img={"https://i.ibb.co/PtbP7n2/Warning.png"}
                            width={700}
                            description={
                            "Basados en las normas gubernamentales correspondientes y/o las de seguridad respectivas según su empresa. Realizamos el acompañamiento respectivo y permanente, desde el diagnostico hasta la aprobación de la entidad certificadora, mejorando la seguridad de la cadena de suministro internacional en cada uno de los actores que hacen parte de la misma."
                            }
                        />
                        </div>
                        <div className="p-4">
                        <CardC
                            foot={""}
                            title={"Auditoría de seguridad de la cadena de suministro"}
                            img={"https://i.ibb.co/hHG8kSb/develop.png"}
                            width={700}
                            description={
                            "Una auditoría de seguridad de la cadena de suministro internacional es un proceso independiente y sistemático para evaluar y analizar los riesgos de seguridad en una cadena de suministro. El objetivo principal es identificar, evaluar y mitigar los riesgos potenciales que podrían afectar la seguridad de los productos, la integridad de la cadena de suministro y la reputación de la organización."
                            }
                        />
                        </div>
                        <div className="p-4">
                        <CardC
                            foot={""}
                            title={
                            "Pre - validaciones y mantenimiento de la certificacion o autorizacion oea"
                            }
                            img={"https://i.ibb.co/fXBZKk5/controls.png"}
                            width={700}
                            description={
                            "Tanto la pre-validación como el mantenimiento de la certificación son procesos cruciales para las empresas que buscan fortalecer su posición en el comercio internacional. Si bien comparten el objetivo de demostrar el compromiso con la seguridad y la confiabilidad en la cadena de suministro, el objetivo es identificar posibles brechas en el cumplimiento de los criterios o requisitos mínimos requeridos de seguridad y brindar la asesoría para fortalecer las buenas prácticas de seguridad y gestión de riesgos de la empresa."
                            }
                        />
                        </div>
                        <div className="p-4">
                        <CardC
                            foot={""}
                            title={
                            "Programas de capacitación en seguridad para la cadena de suministro"
                            }
                            img={"https://i.ibb.co/h81ssG9/conference.png"}
                            width={800}
                            description={
                            "Nuestro programa de capacitación en seguridad de la cadena de suministro busca fortalecer las capacidades de una organización en la identificación, evaluación y mitigación de riesgos potenciales que puedan afectar la seguridad de la cadena de logística. De igual manera es importante concientizar a los empleados sobre la importancia de la seguridad en la cadena de suministro. Promover las buenas prácticas seguras a lo largo de toda la cadena de suministro y fomentando la cultura de seguridad dentro de la organización."
                            }
                        />
                        </div>
                    </Carousel>
                </div>   
            </div>
      </>
  )
}

export function OtrosServicios() {
    return (
        <>
            <div>
                <div className='hidden xl:block'>
                    <div className='font-normal text-[#09588d] text-2xl p-32 mr-4 ml-4'>
                        <p className='text-justify'> Además de los mencionados
                            anteriormente, también ofrecemos
                            servicios complementarios de consultoría
                            específicos para su industria o sector con
                            los que fortalece el crecimiento de la
                            organización:
                        </p>
                        <div className='mt-16'>
                            <div className='grid grid-cols-3'>
                                <CardC
                                    foot={""}
                                    title={"Capacitación"}
                                    width={500}
                                    img={"https://i.ibb.co/YRnVv1S/capacitacion.png"}
                                    description={"Capacitación o socialización en seguridad."}
                                ></CardC>
                                <CardC
                                    foot={""}
                                    title={"Prevención"}
                                    width={500}
                                    img={"https://i.ibb.co/gd8yK3H/warn.png"}
                                    description={"Prevención para el consumo del consumo de alcohol y de estupefacientes en el ambiente laboral."}
                                ></CardC>
                                <CardC
                                    foot={""}
                                    title={"Prevención"}
                                    img={"https://i.ibb.co/1901YWg/alert.png"}
                                    description={
                                        "Prevención de riesgos y respuesta ante emergencias o situaciones de pánico."
                                    }
                                ></CardC>
                            </div>
                            <div className='grid grid-cols-3'>
                                <CardC
                                    foot={""}
                                    title={"Procedimientos y seguridad"}
                                    img={"https://i.ibb.co/2Yg99QM/security.png"}
                                    description={
                                        "Procedimientos de Seguridad básica."
                                    }
                                ></CardC>
                                <CardC
                                    foot={""}
                                    title={"Procedimientos y seguridad"}
                                    img={"https://i.ibb.co/FBqwZYz/cibersecurity.png"}
                                    description={
                                        "Seguridad de la información."
                                    }
                                ></CardC>
                                <CardC
                                    foot={""}
                                    title={"Procedimientos y seguridad"}
                                    img={"https://i.ibb.co/hLcGTRC/people.png"}
                                    description={
                                        "Seguridad personal."
                                    }
                                ></CardC>
                                <CardC
                                    foot={""}
                                    title={"Procedimientos y seguridad"}
                                    img={"https://i.ibb.co/FhqPbsT/undraw-Container-ship-re-alm4.png"}
                                    description={
                                        "Seguridad del contenedor y demás unidades de la carga."
                                    }
                                ></CardC> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className='block xl:hidden p-4'>
                    <div className='font-normal text-[#09588d] text-xl  mr-4 ml-4'>
                        <p className='text-justify'> Además de los mencionados
                            anteriormente, también ofrecemos
                            servicios complementarios de consultoría
                            específicos para su industria o sector con
                            los que fortalece el crecimiento de la
                            organización:
                        </p>
                        <Carousel autoplay={true} loop={true} className="mt-16">
                            <div className="flex justify-center">
                                <CardC
                                foot={""}
                                title={"Capacitación"}
                                width={500}
                                img={"https://i.ibb.co/YRnVv1S/capacitacion.png"}
                                description={"Capacitación o socialización en seguridad."}
                                />
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                foot={""}
                                title={"Prevención"}
                                width={500}
                                img={"https://i.ibb.co/gd8yK3H/warn.png"}
                                description={"Prevención para el consumo del consumo de alcohol y de estupefacientes en el ambiente laboral."}
                                />
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                foot={""}
                                title={"Prevención"}
                                img={"https://i.ibb.co/1901YWg/alert.png"}
                                description={"Prevención de riesgos y respuesta ante emergencias o situaciones de pánico."}
                                />
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                foot={""}
                                title={"Procedimientos y seguridad"}
                                img={"https://i.ibb.co/2Yg99QM/security.png"}
                                description={"Procedimientos de Seguridad básica."}
                                />
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                foot={""}
                                title={"Procedimientos y seguridad"}
                                img={"https://i.ibb.co/FBqwZYz/cibersecurity.png"}
                                description={"Seguridad de la información."}
                                />
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                foot={""}
                                title={"Procedimientos y seguridad"}
                                img={"https://i.ibb.co/hLcGTRC/people.png"}
                                description={"Seguridad personal."}
                                />
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                foot={""}
                                title={"Procedimientos y seguridad"}
                                img={"https://i.ibb.co/FhqPbsT/undraw-Container-ship-re-alm4.png"}
                                description={"Seguridad del contenedor y demás unidades de la carga."}
                                />
                            </div>
                            </Carousel>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Ciberseguridad() {
    return (
        <>
            <div>
                <div className='hidden xl:block xl:block'>
                    <div>
                        <p className='font-normal text-6xl text-white text-center '>Ciberseguridad</p>
                        <p className='text-justify font-light mt-10 text-2xl text-white'>También ofrecemos
                            servicios complementarios de consultoría
                            específicos para el sector de la ciberseguridad en la
                            organización:
                        </p>
                        <div className='xl:flex xl:grid xl:grid-cols-3 mt-12'>
                            <CardC
                                foot={""}
                                title={"Ciberseguridad"}
                                img={"https://i.ibb.co/gytpM7V/cibers.png"}
                                description={
                                    "Servicios de consultoría y asesoría en ciberseguridad para que su empresa pueda proteger sus datos y sistemas de información."
                                }
                            ></CardC> 
                            <CardC
                                foot={""}
                                title={"Ciberseguridad"}
                                img={"https://i.ibb.co/khdDTQk/todolist.png"}
                                description={
                                    "Asesoramiento sobre las mejores prácticas de ciberseguridad."
                                }
                            ></CardC> 
                            <CardC
                                foot={""}
                                title={"Ciberseguridad"}
                                img={"https://i.ibb.co/gP9Dcx9/bug.png"}
                                description={
                                    "Identificación de los riesgos específicos a los que está expuesta la empresa en materia de ciberseguridad."
                                }
                            ></CardC> 
                            <CardC
                                foot={""}
                                title={"Ciberseguridad"}
                                img={"https://i.ibb.co/vmt0xWD/company.png"}
                                description={
                                    "Desarrollo de planes de ciberseguridad personalizados para la empresa."
                                }
                            ></CardC> 
                        </div>
                    </div>
                </div>
                <div className='block xl:hidden p-3'>
                    <div>
                        <p className='font-normal text-4xl text-white text-center '>Ciberseguridad</p>
                        <p className='text-justify font-light mt-10 text-xl text-white mr-4 ml-4'>También ofrecemos
                            servicios complementarios de consultoría
                            específicos para el sector de la ciberseguridad en la
                            organización:
                        </p>
                        <Carousel autoplay={true} loop={true} className="mt-16">
                            <div className="flex justify-center">
                                <CardC
                                    foot={""}
                                    title={"Ciberseguridad"}
                                    img={"https://i.ibb.co/gytpM7V/cibers.png"}
                                    description={
                                        "Servicios de consultoría y asesoría en ciberseguridad para que su empresa pueda proteger sus datos y sistemas de información."
                                    }
                                ></CardC> 
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                    foot={""}
                                    title={"Ciberseguridad"}
                                    img={"https://i.ibb.co/khdDTQk/todolist.png"}
                                    description={
                                        "Asesoramiento sobre las mejores prácticas de ciberseguridad."
                                    }
                                ></CardC>
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                    foot={""}
                                    title={"Ciberseguridad"}
                                    img={"https://i.ibb.co/gP9Dcx9/bug.png"}
                                    description={
                                        "Identificación de los riesgos específicos a los que está expuesta la empresa en materia de ciberseguridad."
                                    }
                                ></CardC> 
                            </div>
                            <div className="flex justify-center">
                                <CardC
                                    foot={""}
                                    title={"Ciberseguridad"}
                                    img={"https://i.ibb.co/vmt0xWD/company.png"}
                                    description={
                                        "Desarrollo de planes de ciberseguridad personalizados para la empresa."
                                    }
                                ></CardC> 
                            </div>                            
                        </Carousel>
                    </div>
                </div>
            </div>
        </>
    )
}

export function Beneficios(){
    return (
        <>
            <div>
                <div>
                    <div className='hidden xl:block p-20'>
                        <p className='text-center font-normal text-6xl text-[#09588d]'>Beneficios de trabajar con nosotros</p>
                        <div className='text-justify font-light mt-16 text-2xl text-[#09588d] flex grid grid-cols-2 gap-16 mb-16'>
                            <div>
                                <div><p className='font-bold'>Reducir el riesgo de pérdidas financieras:</p> La seguridad de la cadena de
                                    suministro puede ayudar a proteger su negocio de pérdidas financieras
                                    causadas por robo, fraude y otros delitos que pueden afectar la integridad
                                    de su negocio.
                                </div><br></br><br></br>
                                <div>
                                    <p className='font-bold'>Mejorar la reputación de la marca:</p> Una cadena de suministro segura
                                    aumenta la confianza de sus clientes y fortalece la reputación de su marca.
                                </div>
                            </div>
                            <div className='text-justify font-light  text-2xl text-[#09588d]'>  
                                <p><p className='font-bold'>Cumplir con las regulaciones:</p> La seguridad en la cadena logística genera
                                    confianza en las autoridades respectivas contribuyendo a la cooperación
                                    mutua y transparencia.
                                </p><br></br><br></br>
                                <p>
                                    <p className='font-bold'>Ganar una ventaja competitiva:</p> Una cadena logística segura le permite
                                    alcanzar un nivel de competitividad alto en el mercado.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className='block xl:hidden p-4 mr-4 ml-4'>
                    <p className='text-center font-normal text-4xl text-[#09588d]'>Beneficios de trabajar con nosotros</p>
                    <div className='text-justify font-light mt-16 text-xl text-[#09588d]  mb-16'>
                        <div>
                            <div><p className='font-bold'>Reducir el riesgo de pérdidas financieras:</p> La seguridad de la cadena de
                                suministro puede ayudar a proteger su negocio de pérdidas financieras
                                causadas por robo, fraude y otros delitos que pueden afectar la integridad
                                de su negocio.
                            </div><br></br><br></br>
                            <div>
                                <p className='font-bold'>Mejorar la reputación de la marca:</p> Una cadena de suministro segura
                                aumenta la confianza de sus clientes y fortalece la reputación de su marca.
                            </div><br></br><br></br>
                        </div>
                        <div className='text-justify font-light  text-xl text-[#09588d]'>  
                            <p><p className='font-bold'>Cumplir con las regulaciones:</p> La seguridad en la cadena logística genera
                                confianza en las autoridades respectivas contribuyendo a la cooperación
                                mutua y transparencia.
                            </p><br></br><br></br>
                            <p>
                                <p className='font-bold'>Ganar una ventaja competitiva:</p> Una cadena logística segura le permite
                                alcanzar un nivel de competitividad alto en el mercado.
                            </p>
                        </div>
                    </div>

                </div>
                </div>
            </div>
        </>
    )
}

export function Text() {
    return(
        <>
            <div>
                <div className='hidden xl:block'>
                    <div className='p-32'>
                        <p  className='text-center font-light text-3xl text-white'>
                            También estamos actualizados sobre las últimas amenazas y
                            tendencias en seguridad de la cadena de logística. Esto nos
                            permite brindar a nuestros clientes las mejores recomendaciones
                            posibles para proteger sus cadenas de suministro.
                        </p>
                        <p  className='text-center font-light mt-16 text-3xl text-white'>
                            Estamos comprometidos a brindar un servicio al cliente
                            excepcional. Trabajaremos en estrecha colaboración con usted para
                            comprender sus necesidades y brindarle las soluciones adecuadas.
                        </p>
                    </div>
                </div>
                <div>
                    <div className='block xl:hidden'>
                        <div className='p-6'>
                            <p  className='text-justify font-light text-xl text-white'>
                                También estamos actualizados sobre las últimas amenazas y
                                tendencias en seguridad de la cadena de logística. Esto nos
                                permite brindar a nuestros clientes las mejores recomendaciones
                                posibles para proteger sus cadenas de suministro.
                            </p>
                            <p  className='text-justify font-light mt-16 text-xl text-white'>
                                Estamos comprometidos a brindar un servicio al cliente
                                excepcional. Trabajaremos en estrecha colaboración con usted para
                                comprender sus necesidades y brindarle las soluciones adecuadas.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export function Contactenos() {
    return (
        <>
            <div>
                <div className='hidden xl:block p-32'>
                    <div className=' font-light text-white'>
                        <p className='text-center text-2xl xs:mr-4'>SERVICIO . SEGURIDAD . RESPALDO</p>
                        <div className=' xl:grid xl:grid-cols-2 text-white mt-20 xl:flex justify-center items-center'>
                            <div className=''>
                                <div className='flex justify-center'>
                                    <img src="https://i.ibb.co/6gHQQbD/logoblanco.png" alt="logoblanco" width={300} className='ml-6'/>
                                </div>
                                <p className='text-xl text-center ml-7'>Consultorías & Soluciones Seguras</p>
                            </div>
                            <div className='flex'>
                                <img src="https://i.ibb.co/0fD2sjy/contacto.png" alt="contact" border="0" width={400}/>
                            </div>

                        </div>
                        <div className='text-center text-sm mt-16'>
                            © 2024 CS2. Todos los derechos reservados.
                        </div>
                    </div>
                </div>
                <div className='block xl:hidden p-4 mr-4 ml-4'>
                    <div className=' font-light text-white'>
                        <p className='text-center text-2xl mt-10'>SERVICIO . SEGURIDAD . RESPALDO</p>
                        <div className=' text-white mt-20  justify-center items-center'>
                            <div className=''>
                                <div className='flex justify-center'>
                                    <img src="https://i.ibb.co/6gHQQbD/logoblanco.png" alt="logoblanco" width={300} className=''/>
                                </div>
                                <p className='text-xl text-center'>Consultorías & Soluciones Seguras</p>
                            </div>
                            <div className='flex'>
                                <img src="https://i.ibb.co/0fD2sjy/contacto.png" alt="contact" border="0" width={400}/>
                            </div>

                        </div>
                        <div className='text-center text-sm mt-16'>
                            © 2024 CS2. Todos los derechos reservados.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}