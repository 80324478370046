
import './App.css';
import Main from './app/pagina/interfaces/ui/pagina-api.jsx'
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <>  
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Main/>} />    
          </Routes>
        </BrowserRouter>
     </>
  );
}

export default App;
